import './App.css';
import { withTheme } from "@rjsf/core";
import { Theme } from "@rjsf/semantic-ui";
import schema from './schema.json'
import { Button } from 'semantic-ui-react';
import { useState } from 'react';


const Form = withTheme(Theme)
const uiSchema = {
  $schema: { "ui:widget": "hidden" },
  "ui:options": {
    "semantic": {
      "fluid": "true",
      "inverted": false,
    }
  }
};


function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

function App() {
  const [formData, setFormData] = useState();
  return (
    <>
      <Button onClick={() => navigator.clipboard.readText().then(JSON.parse).then(setFormData)}>Import from clipboard</Button>
      <hr />
      <Form schema={schema}
        uiSchema={uiSchema}
        onSubmit={(e) => {
          download("skin.json", JSON.stringify(e.formData, null, 2));
        }}
        formData={formData}></Form>
    </>
  );
}

export default App;
